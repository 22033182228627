export const projectsData = [
  {
    id: 1,
    titre: "Stranger Quiz",
    categorie: "Programmation",
    languages: ["Vanilla JS", "modules", "HTML", "SCSS", "SVG"],
    description:
      "Réalisation d'un quiz en Vanilla Js dans le cadre de ma formation. Ce travail a été réalisé individuellement, j'ai pu approfondir ma connaissance de JavaScript, des classes et de la gestion des modules. Le CSS est codé en SCSS et en respectant la méthode de nommage BEM.",
    image: "./img/stranger_quiz.jpg",
    alternative: "copie d'écran du projet Stranger Quiz",
    width: "1920",
    height: "950",
    lien: "https://webfolio.olivierraude.com/stranger_quiz/",
    github: "https://github.com/olivierraude/stranger_quiz",
    //github : ""
  },
  {
    id: 2,
    titre: "Site agence",
    categorie: "Programmation",
    languages: [
      "CSS",
      "HTML",
      "WordPress",
      "ChildTheme",
      "Cpanel",
      "Réseau",
      "SEO",
    ],
    description:
      "Mandat de refonte du site de l'agence Continuum-Communication sur Wordpress. Ce projet m'a donné l'occasion de pratiquer sur Wordpress, différents plugins et sur un constructeur de thème. J'ai également pu apprendre à migrer correctement un site Wordpress. Gestion du volet hébergement, les routes et redirections, la gestion des DNS (particulièrement pour la prise en charge des emails), assurance avec CPanel.",
    image: "./img/continuum.jpg",
    alternative: "copie d'écran du projet Continuum",
    width: "1920",
    height: "950",
    lien: "https://www.continuum-communication.com/",
  },
  {
    id: 3,
    titre: "Webfolio",
    categorie: "Programmation",
    languages: ["ReactJS", "HTML", "SCSS", "GSAP"],
    description:
      "Réalisation de mon webfolio en ReactJS. Projet personnel réalisé seul. J'ai pu apprendre ReactJS, les props, useState/useEffect et les components. J'ai aussi découvert plusieurs librairies Javascript pour la gestion des animations comme Framerjs et Gsap. Le code CSS est codé en SCSS et le site est déployé sur le cloud d'AWS via la plateforme Amplify.",
    image: "./img/webfolio.jpg",
    alternative: "copie d'écran du projet Webfolio",
    width: "1920",
    height: "950",
    //lien : "Vous êtes ici",
    github: "https://github.com/olivierraude/webfolio_site",
  },
  {
    id: 4,
    titre: "Pizzeria",
    categorie: "Programmation",
    languages: ["Thème WP personalisé", "Bedrock", "Gulp", "Composer", "SCSS", "GSAP", "Vanilla JS"],
    description:
      "Réalisation d'un thème Wordpress personnalisé pour le site de la pizzeria Ty Birill. Tout le contenu est administrable via ACF. Essentiel du JavaScript codé en Vanilla, utilisation de plusieurs librairies Javascript pour la gestion des animations et du slider, SwiperJS et Gsap. Gestion du dev front-end avec Gulp et les dépendances PHP avec Composer. Le CSS est codé en SCSS. Projet professionnel, programmation, hébergement, SEO. Vous pouvez voir le détail du projet en suivant le lien Github. (en haut à droite!)",
    image: "./img/tybirill.jpg",
    alternative: "copie d'écran du projet Tybirill",
    width: "1920",
    height: "950",
    lien: "https://dev.olivierraude.me/tybirill/web/",
    github: "https://github.com/olivierraude/wordpress-tybirill",
  },
  {
    id : 5,
    titre : "Agence média",
    categorie : "Programmation",
    languages : ["Elementor Pro", "HTML", "Gulp", "SCSS", "GSAP", "Vanilla JS"],
    description : "Réalisation d'un site web thème personnalisé avec Elementor Pro et GSAP pour une agence de placement média à Montréal. Dernier projet en fin de développement.",
    image : "./img/agence_media.png",
    alternative: "copie d'écran du projet AgenceMedia",
    width: "1920",
    height: "950",
    lien : "https://dev.olivierraude.me/agencemedia/"
  }, 
];
